import React, { useEffect } from "react";
import { openAddSliceOne } from "../../utils/addSlices/addSliceOne";
import { useDispatch } from "react-redux";
import NewSearchCarResult from "./NewSearchCarResult";
import { switchOnAddQueryModalOne } from "../../utils/ModalSlice/modalToggleSlice";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import MainBookingHeading from "../bookingPanel/MainBookingHeadingApi";

function AddLandingOne({CarRentalFew}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openAddSliceOne());
  }, []);

  useEffect(() => {
    const hasModalClosed = Cookies.get("modalClosed");

    if (!hasModalClosed) {
      const timer = setTimeout(() => {
        dispatch(switchOnAddQueryModalOne());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const data = [
    {
      topDesc: {
        title: "Affordable Monthly Car Rentals in Dubai",
        desc: [
          {
            title: "",
            desc: "Looking for a budget-friendly way to drive in Dubai? With WTi, you can enjoy <b>cheap monthly car rental</b> options that don’t compromise on quality. Our extensive fleet includes well-maintained vehicles suited to all preferences, whether you need a compact car, luxury vehicle, or spacious SUV. WTi offers flexible rental plans with no hidden fees, providing an easy and affordable way to explore Dubai.",
          },
        ],
      },
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Cheap Car Rental Dubai | Monthly Car Rental Plans</title>

        <meta
          name="description"
          content="Find the best & cheap prices for daily, weekly, and monthly car rentals in Dubai at WTi. Affordable rates and flexible rental options available."
        />
        <meta
          name="keywords"
          content="cheap car rental dubai monthly"
        />

        <meta name="revisit after" content="7 days" />
        <meta name="robots" content="follow, index" />
        <meta name="language" content="English" />
        <meta name="country" content="United Arab Emirates (UAE)" />
        <meta name="copyright" content="WTi Rent A Car LLC" />
        <meta name="publisher" content="wticarrental.ae" />
        <meta name="distribution" content="Global" />

        <link
          rel="canonical"
          href="https://www.wticarrental.ae/cheap-car-rental-dubai"
        />

      </Helmet>
      <div className="w-full">
      <MainBookingHeading data={data} />
        <div className="w-full pl-[11%] pr-[10.27%] my-8">
          <h2 className=" font-semibold text-3xl text-[#0F0A34]">
            Best prices for daily, weekly, and monthly car rentals
          </h2>
        </div>
        <div className="w-full pl-[10.27%] pr-[10.27%] my-8">
          <NewSearchCarResult CarRentalFew={CarRentalFew}/>
        </div>
      </div>
    </div>
  );
}

export default AddLandingOne;
