import React from "react";

function MainBookingHeading({ data }) {
  const { title } = data?.[0]?.topDesc || "";
  const { desc } = data?.[0]?.topDesc || [];
  const createMarkup = (content) => ({ __html: content });

  return (
    <>
      {data?.[0]?.topDesc && (
        <div className="w-full mb-2 pl-[10.27%] pr-[10.27%] flex flex-col text-center items-center py-16 max-sm:text-center bg-[#F4F4F4]" key={'1'}>
          <div className="font-bold text-3xl running-text pb-4 text-[#0F0A34]">
            <h2>{title}</h2>
          </div>
          <div className="text-center text-[15px] leading-6">
            {desc?.length
              ? desc?.map((item, index) => {
                  const { title, desc } = item || "";
                  return (
                    <>
                      {title ? (
                        <div className="mb-2" key={index}>
                          <h3 className="font-bold text-[18px] text-left text-[#0F0A34]">
                            {title}
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                      <p
                        className=""
                        dangerouslySetInnerHTML={createMarkup(desc)}
                        key={index}
                      ></p>
                      <br></br>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
      )}
    </>
  );
}

export default MainBookingHeading;
